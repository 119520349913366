.indicator {
  color: rgba(87, 97, 114, 0.7);
}

.indicator.success {
  color: rgb(0, 194, 82);
}

.indicator.failure {
  color: rgb(228, 37, 42);
}

