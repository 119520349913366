.container {
  padding: 3em 0;
  min-height: 100vh;
  display: flex;
  align-items: center;
}

.logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.checker {
  margin-bottom: 1.25em;
  float: left;
  width: 100%;
  display: flex;
  align-items: center;
}

.checker .indicator {
  font-size: 2em;
  float: left;
  text-align: center;
  line-height: 1;
}

.checker::before {
  transform: translateY(2px);
  display: block;
}

.checker .checker-label {
  float: left;
  margin-left: 1em;
}

.checker .title {
  font-size: 1.75em;
  color: rgb(87, 97, 114);
  font-family: proxima-soft, sans-serif;
  font-weight: 300;
  line-height: 1;
  margin-bottom: 0.25em;
}

.checker .domain {
  color: rgba(87, 97, 114, 0.7);
  font-size: 0.875em;
  display: block;
  font-family: open-sans, sans-serif;
  font-weight: 300;
  line-height: 1;
}

.checker::last-child {
  margin-bottom: 0;
}

.checker.failure .domain {
  color: rgb(228, 37, 42);
}
