body {
  font-family: "Open Sans", sans-serif;
  color: rgb(87, 97, 114);
}

.color-slate {
  color: rgb(87, 97, 114);
}

.color-red {
  color: rgb(228, 37, 42);
}

